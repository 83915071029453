const Search = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5987 9.16927C14.5987 12.17 12.1661 14.6026 9.16536 14.6026C6.16462 14.6026 3.73203 12.17 3.73203 9.16927C3.73203 6.16852 6.16462 3.73594 9.16536 3.73594C12.1661 3.73594 14.5987 6.16852 14.5987 9.16927ZM13.6378 14.9813C12.4 15.9353 10.8489 16.5026 9.16536 16.5026C5.11528 16.5026 1.83203 13.2194 1.83203 9.16927C1.83203 5.11918 5.11528 1.83594 9.16536 1.83594C13.2155 1.83594 16.4987 5.11918 16.4987 9.16927C16.4987 10.8509 15.9327 12.4002 14.9808 13.6373L18.959 17.6155C19.33 17.9865 19.33 18.588 18.959 18.959C18.588 19.33 17.9865 19.33 17.6155 18.959L13.6378 14.9813Z"
      />
    </svg>
  )
}

export default Search
